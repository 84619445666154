import React, { useState, useEffect, useRef } from "react";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import Confetti from "react-confetti";

export function Invite() {
  const [page, setPage] = useState(1);
  const [excited, setExcited] = useState(20);

  const [showConfetti, setShowConfetti] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(new Date()); // Initialize time as a Date object
  const [showTimeInput, setShowTimeInput] = useState(false);
  const [sliderPos, setSliderPos] = useState({ x: 0, y: 0 });
  const sliderRef = useRef(null);
  const [choices, setChoices] = useState({
    drinking: false,
    movies: false,
    bully: false,
    compliment: false,
  });
  const [gomaPosition, setGomaPosition] = useState("-250px"); // Initial position outside the screen

  const [showAngryGoma, setShowAngryGoma] = useState(false); // State to control Goma visibility

  const triggerAngryGoma = () => {
    setShowAngryGoma(true); // Show the image in the DOM

    // Delay to trigger the animation
    setTimeout(() => {
      setGomaPosition("-35px"); // Move Goma into view after a small delay
    }, 50); // 50ms delay before moving the image

    // Move Goma back after 2 seconds
    setTimeout(() => {
      setGomaPosition("-250px"); // Move Goma back outside the screen
      setTimeout(() => {
        setShowAngryGoma(false); // Hide after the animation is finished
      }, 1000); // Wait for the transition to complete before hiding the image
    }, 2000); // Stay in view for 2 seconds
  };

  const updateConfettiPosition = (e) => {
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const sliderWidth = sliderRect.width;
    const sliderLeft = sliderRect.left;
    const sliderTop = sliderRect.top;

    const newPosX = sliderLeft + (sliderWidth * e.target.value) / 100;
    setSliderPos({ x: newPosX, y: sliderTop + window.scrollY });
  };

  const handleChoiceChange = (choice) => {
    if (choice === "bully") {
      if (!choices.bully) {
        triggerAngryGoma();
      }
    }
    setChoices((prevChoices) => ({
      ...prevChoices,
      [choice]: !prevChoices[choice],
    }));
  };

  const [noButtonStyle, setNoButtonStyle] = useState({
    backgroundColor: "#ffb6c1",
    border: "none",
    color: "#fff",
    padding: "0.8rem 1.5rem",
    borderRadius: "1.5rem",
    fontSize: "1.2rem",
    cursor: "pointer",
    transition: "all 0.3s ease",
    boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
    position: "relative",
  });

  const handleMouseEnterNo = () => {
    const newX = Math.random() * 150; // Move the button randomly left or right
    const newY = Math.random() * 300; // Move the button randomly up or down
    setNoButtonStyle((prev) => ({
      ...prev,
      transform: `translate(${newX}px, ${newY}px)`, // Apply the random movement
    }));
  };

  const handleTimeChange = (hours, minutes) => {
    const newTime = new Date(selectedTime);
    newTime.setHours(hours);
    newTime.setMinutes(minutes);
    setSelectedTime(newTime); // Update clock based on input change
  };

  useEffect(() => {
    if (excited === 100) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000);
    }
  }, [excited]);

  let content;
  if (page === 1) {
    content = (
      <>
        <h1>Would you like to go on a date with me? :3</h1>
        <div
          style={{ display: "flex", gap: "1.5rem", justifyContent: "center" }}
        >
          <button
            style={{
              backgroundColor: "#ffb6c1",
              border: "none",
              color: "#fff",
              padding: "0.8rem 1.5rem",
              borderRadius: "1.5rem",
              fontSize: "1.2rem",
              cursor: "pointer",
              transition: "all 0.3s ease",
              boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff69b4")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#ffb6c1")}
            onClick={(e) => {
              e.preventDefault();
              setPage(2);
            }}
          >
            Yes
          </button>

          <button
            style={noButtonStyle}
            onMouseEnter={handleMouseEnterNo}
            onClick={(e) => {
              e.preventDefault();
              handleMouseEnterNo();
              triggerAngryGoma();
            }}
          >
            No
          </button>
        </div>
      </>
    );
  }

  if (page === 2) {
    content = (
      <>
        <h1>YAYYYYYYY DONT GO ANYWHERE</h1>
        <img src="/yayy.gif" alt="Dancing cat" style={{ width: "200px" }} />
        <button
          style={{
            backgroundColor: "#ffb6c1",
            border: "none",
            color: "#fff",
            padding: "0.8rem 1.5rem",
            borderRadius: "1.5rem",
            fontSize: "1.2rem",
            cursor: "pointer",
            transition: "all 0.3s ease",
            boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
          }}
          onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff69b4")}
          onMouseLeave={(e) => (e.target.style.backgroundColor = "#ffb6c1")}
          onClick={(e) => {
            e.preventDefault();
            setPage(3);
          }}
        >
          next question
        </button>
      </>
    );
  }

  if (page === 3) {
    const hours = selectedTime.getHours();
    const minutes = selectedTime.getMinutes();
    content = (
      <>
        {!showTimeInput ? (
          <div
            style={{
              margin: "5rem 0",
            }}
          >
            <h1>When are you free? 🦅</h1>
            <DateTime
              dateFormat="YYYY-MM-DD"
              timeFormat={false}
              value={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                setShowTimeInput(true); // Show the time input after selecting a date
              }}
              inputProps={{
                placeholder: "Pick a date u dummy",
                style: {
                  padding: "0.8rem",
                  textAlign: "center",
                  fontSize: "1.2rem",
                  borderRadius: "1rem",
                  border: "1px solid #ffb6c1",
                  boxShadow: "0 5px 10px rgba(255, 182, 193, 0.2)",
                },
              }}
            />
          </div>
        ) : (
          <>
            <h1>At what time :o</h1>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Clock value={selectedTime} renderNumbers={true} size={200} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "1rem",
                marginTop: "1rem",
                alignItems: "center",
              }}
            >
              <label>
                <input
                  type="range"
                  min="0"
                  max="23"
                  value={hours}
                  onChange={(e) =>
                    handleTimeChange(Number(e.target.value), minutes)
                  }
                  style={{
                    width: "200px",
                    margin: "0.5rem",
                    accentColor: "#ffb6c1",
                  }}
                />
              </label>
              <label>
                <input
                  type="range"
                  min="0"
                  max="59"
                  value={minutes}
                  onChange={(e) =>
                    handleTimeChange(hours, Number(e.target.value))
                  }
                  style={{
                    width: "200px",
                    margin: "0.5rem",
                    accentColor: "#ffb6c1",
                  }}
                />
              </label>
            </div>
            {selectedDate && selectedTime && (
              <div style={{ marginTop: "1rem", color: "#ff69b4" }}>
                <p>{`You picked: ${selectedDate.format(
                  "MMMM Do YYYY"
                )}, ${selectedTime.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`}</p>
              </div>
            )}
            <button
              style={{
                backgroundColor: "#ffb6c1",
                border: "none",
                color: "#fff",
                padding: "0.8rem 1.5rem",
                borderRadius: "1.5rem",
                fontSize: "1.2rem",
                cursor: "pointer",
                transition: "all 0.3s ease",
                boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff69b4")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#ffb6c1")}
              onClick={(e) => {
                e.preventDefault();
                setPage(4);
              }}
            >
              Done
            </button>
          </>
        )}
      </>
    );
  }

  const Text = ({ children }) => (
    <div
      style={{
        fontFamily: "lovely_eunike_hans",
      }}
    >
      {children}
    </div>
  );

  if (page === 4) {
    content = (
      <>
        <h1>What do you want to do?</h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <button
            onClick={() => handleChoiceChange("drinking")}
            style={{
              border: choices.drinking
                ? "2px solid #ff69b4"
                : "2px solid transparent",
              borderRadius: "1rem",

              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <img
              src="https://st4.depositphotos.com/3332767/23682/i/450/depositphotos_236828878-stock-photo-senior-man-woman-holding-glasses.jpg"
              alt="Drinking"
              style={{ width: "200px" }}
            />
            <Text>Drinking</Text>
          </button>

          <button
            onClick={() => handleChoiceChange("movies")}
            style={{
              border: choices.movies
                ? "2px solid #ff69b4"
                : "2px solid transparent",
              borderRadius: "1rem",
              padding: "1rem",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQayQuUkQCIf9i3kdrsBg1UKAXF3OB2PX0JkQ"
              alt="Watching Movies"
              style={{ width: "150px", height: "100px" }}
            />
            <Text>Movies</Text>
          </button>

          <button
            onClick={() => handleChoiceChange("bully")}
            style={{
              border: choices.bully
                ? "2px solid #ff69b4"
                : "2px solid transparent",
              borderRadius: "1rem",
              padding: "1rem",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <img
              src="/bully.png"
              alt="Bully Wiljam"
              style={{ width: "150px", height: "150px" }}
            />
            <Text>Bully Wiljam</Text>
          </button>

          <button
            onClick={() => handleChoiceChange("compliment")}
            style={{
              border: choices.compliment
                ? "2px solid #ff69b4"
                : "2px solid transparent",
              borderRadius: "1rem",
              padding: "1rem",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            <img
              src="/wiljam.png"
              alt="Compliment Wiljam"
              style={{ width: "120px", height: "150px" }}
            />
            <Text>Compliment Wiljam</Text>
          </button>
        </div>
        <button
          style={{
            backgroundColor: "#ffb6c1",
            border: "none",
            color: "#fff",
            padding: "0.8rem 1.5rem",
            borderRadius: "1.5rem",
            fontSize: "1.2rem",
            cursor: "pointer",
            transition: "all 0.3s ease",
            boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
            marginTop: "1rem",
          }}
          onClick={(e) => {
            e.preventDefault();
            setPage(5);
          }}
        >
          Done!
        </button>
      </>
    );
  }

  let pic = "/excited.gif";
  if (excited < 20) {
    pic = "/hm.gif";
  }
  if (excited > 99) {
    pic = "/woo.gif";
  }

  if (page === 5) {
    content = (
      <>
        <div>
          <img src={pic} style={{ height: "200px" }} />
          <h1> How excited are you?? </h1>
          <input
            type="range"
            ref={sliderRef}
            value={excited}
            min="0"
            max="100"
            step="1"
            style={{ width: "100%", margin: "0.5rem", accentColor: "#ffb6c1" }}
            onChange={(e) => {
              // make balloons and confetti
              setExcited(e.target.value);
              updateConfettiPosition(e);
            }}
          />
          {excited == 100 && (
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              confettiSource={{
                x: sliderPos.x,
                y: sliderPos.y,
                w: 10,
                h: 10,
              }}
            />
          )}
          <button
            style={{
              backgroundColor: "#ffb6c1",
              border: "none",
              color: "#fff",
              padding: "0.8rem 1.5rem",
              borderRadius: "1.5rem",
              fontSize: "1.2rem",
              cursor: "pointer",
              transition: "all 0.3s ease",
              boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
              marginTop: "1rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              setPage(6);
            }}
          >
            {"Onward"}
          </button>
        </div>
      </>
    );
  }

  if (page === 6) {
    content = (
      <>
        <h1 style={{ fontSize: "48px" }}>See you then!!❤️❤️❤️☺️</h1>
        <p style={{ padding: "0", margin: "0" }}>
          {`Don't forget ${selectedDate.format(
            "MMMM Do YYYY"
          )}, ${selectedTime.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}`}{" "}
        </p>
        <p style={{ padding: "0", margin: "0" }}>{">:o"}</p>
      </>
    );
  }

  return (
    <>
      {excited >= 90 && showConfetti && (
        <>
          <Confetti width={window.innerWidth} height={window.innerHeight} />
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            color: "#c45380",
            textAlign: "center",
            backgroundColor: "white",
            padding: "2rem",
            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: "2rem",
            width: "300px",
            margin: "2rem 0",
          }}
        >
          {content}
        </div>

        {page !== 1 && (
          <div>
            <button
              style={{
                backgroundColor: "#ffb6c1",
                border: "none",
                color: "#fff",
                padding: "0.4rem 1rem",
                borderRadius: "1.5rem",
                fontSize: "0.8rem",
                cursor: "pointer",
                transition: "all 0.3s ease",
                boxShadow: "0 5px 15px rgba(255, 182, 193, 0.3)",
                marginBottom: "2rem",
              }}
              onClick={(e) => {
                e.preventDefault();

                setExcited(20);
                setShowConfetti(true);
                setPage((curr) => {
                  if (page === 3) {
                    if (showTimeInput) {
                      setShowTimeInput(false);
                      return curr;
                    } else {
                      setShowTimeInput(false);
                      setSelectedDate(null);
                    }
                  }

                  return curr - 1;
                });
              }}
            >
              Go back for some reason
            </button>
          </div>
        )}
      </div>
      {showAngryGoma && (
        <img
          src="/angry.png"
          style={{
            width: "200px",
            position: "fixed",
            bottom: gomaPosition, // Dynamically set bottom value for animation
            left: "50%",
            transform: "translateX(-50%)",
            transition: "bottom 1s ease", // Smooth transition for the animation
          }}
          alt="Angry Goma"
        />
      )}
    </>
  );
}
